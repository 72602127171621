import React from "react"
import MedTrailIcon from "!svg-react-loader!../images/vectors/medtrail_logo.svg"
import { Link } from "gatsby"

const Header = () => (
  <>
    <header className={`header container-fluid non-sticky`} id='navbar'>
      <div className={`row align-items-center`}>
        <div className='col-md-4 header-title'>
          <Link to='/'>
            <span className="cursor-pointer">
            <MedTrailIcon/>
          </span>
          </Link>
        </div>
      </div>
    </header>
  </>

)

export default Header;
