export const PROD_URL = process.env.GATSBY_API_URL;
export const REQUEST_DEMO_API = `${PROD_URL}/api/v0/requestDemo`;
export const ROUTE_FLAG = {
  paymentPolicyPage: 'paymentPolicyPage',
  homePage: 'homePage',
};

export const FEATURES_DATA = {
  'diagnostics':{
    title:'Multiple labs to provide better choices for patients',
    description:[
      'Multiple lab choices enhance trust from patients. By partnering with us, you can provide them a range of options  to choose from.',
    ],
    image:'diagnostics.png',
    orientation:'right'
  },
  'diagnostics_price':{
    title:'Best prices unlocked for doctor community',
    description:[
      'With hundreds of doctors on MedTrail Smart Diagnostics platform, we ensure best prices for doctors from all the labs',
    ],
    image:'diagnostics_price.png',
    orientation:'left'
  },
  'diagnostics_transparent':{
    title:'Transparent patient attribution',
    description:[
      'We link every lab report with digitised prescription to ensure complete transparency for you',
      'Any repeat lab test booking done by the patient  is attributed to the doctor for lifetime.',
    ],
    image:'diagnostics_transparent.png',
    orientation:'right'
  },
  'diagnostics_support':{
    title:'Personal Support!',
    description:[
      'Dedicated Relationship Manager to help run your diagnostics better',
      'Train existing staff and follow best-in-class diagnostic practices',
    ],
    image:'diagnostics_support.png',
    orientation:'left'
  },
  'diagnostics_benefit':{
    title:'Added Benefits',
    description:[
      'Single MedTrail App for all patient records',
      'Custom packages based on Practice trends',
      'Automated collection and settlement of Payment transaction',
    ],
    image:'diagnostics_benefit.png',
    orientation:'right'
  },
}

export const SUCCESS_FLAG = 'success';
export const ERROR_FLAG = 'error';
export const DEMO_TYPE = 'diagnostics';
export const WEBSITE = 'DIAGNOSTICS';
